.cards {
  display: flex;
  flex-direction: column;
  width: 320px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin: 0.8rem;
}

.title {
  text-align: left;
  font-weight: 400;
  font-size: larger;
  padding-bottom: 12px;
}

.title h1 {
  font-size: 20px;
}

.description {
  color: #777777;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-line-clamp: 3;
  -moz-box-orient: vertical;
}

.cards button {
  height: 40px;
  width: 100%;
  border-radius: 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
