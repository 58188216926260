.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.cardsList {
  width: 100%;
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.btnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.createBtn {
  border: none;
  background-color: #ffe420;
  width: 150px;
  height: 40px;
  border-radius: 4px;
  font-weight: 800;
  cursor: pointer;
}

.tableContainer {
  margin-top: 10px;
  height: 560px;
}
