.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.tableContainer {
  width: 100%;
  margin: 1rem auto;
}

.btnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
