.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
}

.header {
  position: relative;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}

.header:hover {
  cursor: pointer;
  filter: brightness(1.2);
}

.filters {
  max-height: 0;
  transition: max-height 0.2s ease-out, padding 0.2s ease-in;
  overflow: hidden;
  padding: 0px;
  padding-bottom: 0px;
}

.filtersBadge {
  position: absolute;
  top: -5px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.showFilters {
  max-height: 420px;
  transition: max-height 0.2s ease-in, padding 0.2s ease-out;
  padding: 20px;
  padding-top: 0px;
  overflow: hidden;
}

.row {
  display: flex;
}

.row > div {
  margin-right: 15px;
}

.row > div:last-child {
  margin-right: 0px;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.label {
  margin-bottom: 5px;
}

.formField {
  margin-bottom: 10px !important;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
.actions > button {
  margin-left: 5px;
}
