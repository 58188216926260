.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-inline: 16px;
  padding-block: 8px;
}

.actions {
  display: flex;
  align-items: center;
}

.actions:hover {
  cursor: pointer;
}
