:root {
  --md-sys-color-primary: rgb(112 93 13);
  --md-sys-color-surface-tint: rgb(112 93 13);
  --md-sys-color-on-primary: rgb(255 255 255);
  --md-sys-color-primary-container: rgb(252 225 134);
  --md-sys-color-on-primary-container: rgb(35 27 0);
  --md-sys-color-secondary: rgb(67 104 51);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(196 239 172);
  --md-sys-color-on-secondary-container: rgb(6 33 0);
  --md-sys-color-tertiary: rgb(0 105 113);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(157 240 250);
  --md-sys-color-on-tertiary-container: rgb(0 32 35);
  --md-sys-color-error: rgb(186 26 26);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(255 218 214);
  --md-sys-color-on-error-container: rgb(65 0 2);
  --md-sys-color-background: rgb(255 248 239);
  --md-sys-color-on-background: rgb(30 27 19);
  --md-sys-color-surface: rgb(255 248 239);
  --md-sys-color-on-surface: rgb(30 27 19);
  --md-sys-color-surface-variant: rgb(234 226 207);
  --md-sys-color-on-surface-variant: rgb(75 70 57);
  --md-sys-color-outline: rgb(125 119 103);
  --md-sys-color-outline-variant: rgb(206 198 180);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(52 48 39);
  --md-sys-color-inverse-on-surface: rgb(248 240 226);
  --md-sys-color-inverse-primary: rgb(222 197 109);
  --md-sys-color-primary-fixed: rgb(252 225 134);
  --md-sys-color-on-primary-fixed: rgb(35 27 0);
  --md-sys-color-primary-fixed-dim: rgb(222 197 109);
  --md-sys-color-on-primary-fixed-variant: rgb(85 69 0);
  --md-sys-color-secondary-fixed: rgb(196 239 172);
  --md-sys-color-on-secondary-fixed: rgb(6 33 0);
  --md-sys-color-secondary-fixed-dim: rgb(169 210 146);
  --md-sys-color-on-secondary-fixed-variant: rgb(44 79 29);
  --md-sys-color-tertiary-fixed: rgb(157 240 250);
  --md-sys-color-on-tertiary-fixed: rgb(0 32 35);
  --md-sys-color-tertiary-fixed-dim: rgb(129 211 221);
  --md-sys-color-on-tertiary-fixed-variant: rgb(0 79 85);
  --md-sys-color-surface-dim: rgb(224 217 204);
  --md-sys-color-surface-bright: rgb(255 248 239);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(250 243 229);
  --md-sys-color-surface-container: rgb(245 237 223);
  --md-sys-color-surface-container-high: rgb(239 231 218);
  --md-sys-color-surface-container-highest: rgb(233 226 212);
}
