.form {
  display: flex;
  flex-direction: column;
}

.form > div {
  margin-bottom: 10px;
}

.form > h3 {
  margin-block: 10px;
}
