.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

@media screen and (max-width: 600px) {
  .gridContainer {
    grid-template-columns: 1fr;
  }
}
