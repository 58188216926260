.container {
  display: flex;
  flex-direction: column;
  width: var(--sidebar-width);
  height: calc(100vh - 90px);
  padding-inline: 10px;
  margin-top: 20px;
  transition: width 0.3s ease-in-out;
}

.links a {
  display: flex;
  align-items: center;
  border-radius: 30px;
  padding: 10px;
  text-decoration: none;
  color: var(--md-sys-color-on-primary-container);
}

.links a:hover {
  background-color: var(--md-sys-color-primary);
  color: var(--md-sys-color-on-primary);
}
.links > li > a > span {
  margin-left: 5px;
}
