.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.tableBeer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
}

.tableBeerEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 300px;
}

.rowBox {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.rowBoxDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.columnBeer {
  font-size: 14px;
  font-weight: 600;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.rowBeer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  margin: 0.5rem;
}

.divider {
  height: 3px;
  width: 100%;
  margin: 0.2rem auto;
  background-color: #ccc;
}

.totalValue {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.totalValue span {
  color: #b1913b;
  font-size: 24px;
  font-weight: 600;
}
