.adImagesContainer {
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 10px;
}

.header:hover {
  cursor: pointer;
}
