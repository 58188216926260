.success-container {
    border-left: 8px solid #2E7D32;
    position: absolute;
    right: 64px;
    padding: 16px;
    top: 32px;
    justify-content: space-evenly;
    border-radius: 5px;
    display: flex;
    width: 40%;
    max-width: 400px;
    height: 82px;
    align-items: center;
    background-color: #EAF2EA;    
}

.error-container {
    border-left: 8px solid #D32F2F;
    position: absolute;
    right: 64px;
    padding: 16px;
    top: 32px;
    justify-content: space-evenly;
    border-radius: 5px;
    display: flex;
    width: 40%;
    max-width: 400px;
    height: 82px;
    align-items: center;
    background-color: #FBEAEA;
}

.base-container {
    border-left: 8px solid #0288D1;
    position: absolute;
    right: 64px;
    padding: 16px;
    top: 32px;
    justify-content: space-evenly;
    border-radius: 5px;
    display: flex;
    width: 40%;
    max-width: 400px;
    height: 82px;
    align-items: center;
    background-color: #E6F3FA;
}

.warning-container {
    border-left: 8px solid #ED6C02;
    position: absolute;
    right: 64px;
    padding: 16px;
    top: 32px;
    justify-content: space-evenly;
    border-radius: 5px;
    display: flex;
    width: 40%;
    max-width: 400px;
    height: 82px;
    align-items: center;
    background-color: #FDF0E6;
}

.message-icon {
    max-width: 64px;
    max-height: 64px;
}

.message-content {
    align-items: flex-start;
}
.message-content h1 {
    font-size: 28px;
    font-weight: bold;
}
.message-content h3 {
    font-size: 16px;
    font-weight: lighter;
}


#hideMe {
    -webkit-animation: cssAnimation 5s forwards; 
    animation: cssAnimation 5s forwards;
}
@keyframes cssAnimation {
    0%   {opacity: 1;}
    50%  {opacity: 0.7;}
    100% {opacity: 0;}
}
@-webkit-keyframes cssAnimation {
    0%   {opacity: 1;}
    50%  {opacity: 0.7;}
    100% {opacity: 0;}
}