.form {
  display: flex;
  flex-direction: column;
}

.form > div {
  margin-bottom: 10px;
}

/* .advertisementSet {
  background-color: white;
  border-radius: 20px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  min-width: 100px;
}
 */
