.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 90%;
  background-color: #fff;
  box-shadow: 24;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
}

.modal h1 {
  margin: 1rem 0;
}

.inputsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.inputBox {
  display: flex;
  flex-direction: column;
}

.input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.input label {
  color: #ccc;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.input input {
  border: none;
  border-bottom: 3px solid #ccc;
  width: 100%;
  margin-bottom: 0.5rem;
  height: 30px;
  font-size: 14px;
}

.input input:focus {
  border-bottom: 3px solid #ffe420;
  outline: none;
}
