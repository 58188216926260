html {
  font-size: 16px;
  font-family: Roboto, "sans-serif";
  max-width: 100vw;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

:root {
  --sidebar-width: 220px;
}

.appContainer {
  color: var(--md-sys-color-on-surface);
  position: relative;
  display: flex;
  min-height: calc(100vh - 60px); /* 100vh minus header height */
}

@media screen and (max-width: 1200px) {
  :root {
    --sidebar-width: 70px;
  }
}
