.loginMain {
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logBox {
  height: 600px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}

.logBox img {
  width: 223px;
  height: 146px;
  margin-bottom: 1rem;
}

.logBox span {
  font-size: 14px;
  text-align: center;
  width: 80%;
}

.logBox h1 {
  font-size: 28px;
  margin: 0.5rem;
}

.logBox form {
  padding: 15px;
}
